/* Subscription List - START */
.subscription-jumbotron-container {
    display: flex;
    overflow-x: auto;
    padding: 20px 0;
    mask-image: linear-gradient(to right, transparent 0px, white var(--left-mask-size, 0px), white calc(100% - var(--right-mask-size, 0px)), transparent 100%), linear-gradient(to top, white 5px, transparent 5px);
}

.can-scroll-right {
    --right-mask-size: 45px;
}

.can-scroll-left {
    --left-mask-size: 45px;
}

/* Subscription List - END */

/* Subscription Duration - START */
.subscription-duration__container {
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
/* Subscription Duration - END */


/* Subscription Card - START */
.subscription-card {
    padding: 20px;
    border-radius: 15px;
    margin: 0 10px;
    width: 300px;
    /* Used to remove flex from parent component */
    flex: none;

    /* Used to align items */
    display: flex;
    flex-direction: column;
}

.subscription-card h2 {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
}

.subscription-card ul {
    text-align: left;
    padding-left: 0;
    list-style-type: none;
}

.subscription-card li {
    display: flex;
    align-items: start;
    margin-bottom: 5px;
}

.subscription-card .feature-icon {
    min-width: 20px;
    margin-right: 10px;
}

.subscription-card .feature-text {
    margin: 0;
}

.subscription-card .subscription-button {
    margin-top: auto;
    width: 100%;
}

.subscription-card-bg-colour-grey {
    background-color: #CCCCCC;
}
.subscription-card-bg-colour-blue {
    background-color: #307AFF;
}
.subscription-card-bg-colour-amber {
    background-color: #FFD700;
}
.subscription-card-bg-colour-black {
    background-color: #000000;
}

.subscription-card-text-colour-black {
    color: #000000;
}
.subscription-card-text-colour-white {
    color: #FFFFFF;
}

/* Subscription Card - END */
