.iframe-container {
  position: absolute; /* Ensure the container positions its children absolutely within the parent */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure the iframe container is above other elements */
}

.web-app-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
