@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.subscription-jumbotron-container {
    display: flex;
    overflow-x: auto;
    padding: 20px 0;
    scroll-behavior: smooth;
    position: relative;
}

.subscription-jumbotron-container.can-scroll-left::before,
.subscription-jumbotron-container.can-scroll-right::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    z-index: 1;
}

.subscription-jumbotron-container.can-scroll-left::before {
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.subscription-jumbotron-container.can-scroll-right::after {
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.subscription-jumbotron-container::-webkit-scrollbar {
    display: none;
}

.subscription-card {
    flex: 0 0 auto;
    width: 292px;
    margin-right: 15px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    font-family: 'Lato', sans-serif;
}

.subscription-card h3 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #7f459b;
}

.subscription-card ul {
    list-style: none;
    padding: 0;
}

.subscription-card ul li {
    margin-bottom: 10px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

.subscription-card.free {
    background-color: grey;
    color: black;
}

.subscription-card.subscriber {
    background-color: blue;
    color: white;
}

.subscription-card.subscriber ul li {
    color: white; /* Ensure text color is white */
}

.subscription-card.premium {
    background-color: amber;
    color: black;
}

.subscription-card.vip {
    background-color: black;
    color: white;
}

.subscription-card.vip ul li {
    color: white; /* Ensure text color is white */
}
