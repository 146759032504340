@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  background: radial-gradient(circle at bottom right, #7c4a9dff, #6c70b4ff);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
}

.app-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 100px); /* Adjusting for footer height */
    background: transparent; /* Making the container background transparent */
    flex-grow: 1; /* Ensures content takes up remaining space */
}

.login-content {
    background: transparent; /* Remove background color */
    padding: 0; /* Remove padding to make it blend */
    text-align: center;
    width: 100%;
    max-width: 600px;
}

.app-logo {
    margin-top: 0px; /* Adjust margin to position logo */
    margin-bottom: 20px; /* Space between logo and the text */
    width: 120px; /* Smaller logo size */
    height: auto; /* Maintains aspect ratio */
}

.app-text {
    margin-bottom: 20px; /* Space between text and the event list */
    font-size: 18px;
    color: white; /* Make text white to contrast with background */
}

.app-event-list {
    margin-bottom: 40px; /* Space between event list and the registration form */
}

.app-registration-form {
    margin-top: 20px;
}

footer {
    position: relative; /* Changed from fixed to relative */
    width: 100%;
    height: 100px; /* Adjust footer height as needed */
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin-top: auto; /* Ensures footer stays at the bottom of content */
}
