
/* .overlay-title {
    position: absolute;
    top:100px;
    width: 50%;
    text-align: center;
    z-index: 1050;
    color: white;
} */

/* .quiz-jumbotron-title {
    font-size: 10rem;
} */

.quiz-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    max-width: 600px;
    padding: 20px;

    background: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

    color: #483D8B;

    z-index: 900;
}

.quiz-jumbotron-title {
    font-size: 5rem !important;
}

@media (max-width: 767px) {
    .quiz-jumbotron-title {
        font-size: 3rem !important;
    }
}

.quiz-question-container {
    width: 100%;
    padding: 20px;
    font-family: 'Lato', sans-serif;
}

.quiz-question-container__question-text {
    font-family: 'Lato', sans-serif;
}

.quiz-question-container__input {
    width: 100%;
}

.quiz-question-container__view-emoji-container {
    display: flex;
    justify-content: space-between;
}

.quiz-button-container {
    display: flex;
    justify-content: flex-end;

    width: 100%;
}

.next-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.next-button:hover {
    background-color: #0056b3;
}