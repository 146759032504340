/*
 * Roxy: Bootstrap template by GettTemplates.com
 * https://gettemplates.co/roxy
 */
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500);
html,
body {
    width: 100%;
    overflow-x: hidden;
}

body {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

body {
    margin: 0;
    padding: 0;
    background: #ffff;
    font-size: 16px;
    color: #222;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

*:focus {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Work Sans', sans-serif;
    margin-top: 0;
}

a {
    color: #ff00cc;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    background-color: transparent;
}

a:focus,
button:focus {
    outline: none;
}

.btn:hover,
a:hover {
    text-decoration: none;
    color: #333399;
}

img {
    max-width: 100%;
}

.bg-fixed {
    background-size: cover;
}

.bg-grey {
    background-color: #f7f8f9;
}

.fs-40 {
    font-size: 40px;
}

section {
    position: relative;
}

.section-content {
    padding-top: 80px;
    padding-bottom: 80px;
}

.title-wrap,
.section-title {
    text-align: center;
}

.shadow {
    box-shadow:
        0 10px 30px 0 rgba(67, 80, 98, 0.24),
        0 2px 4px 0 rgba(67, 80, 98, 0.1) !important;
}

.text-white {
    color: #fff;
}

/*=======================================================
 =          CSS that doesn't seem to be in use          =
 ========================================================*/

/*==============================
 =            BUTTON            =
 ==============================*/
.btn {
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    padding: 9px 25px;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
}

.btn-primary {
    display: inline-block;
    background-color: #ff00cc;
    border-color: #ff00cc;
    color: #fff;
}
.btn-primary:hover {
    background-color: #333399;
    border-color: #333399;
    color: #fff;
}

.btn-outline-primary {
    color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: #fff;
}
.btn-outline-primary:hover {
    background-color: #333399;
    border-color: #333399;
    color: #fff;
}

.btn-shadow {
    box-shadow: -5px 4px 10px 0px rgba(229, 17, 2, 0.068);
}

.btn-icon {
    color: #000;
}

.btn-link {
    color: #ff00cc;
}

.primary-button {
    background: #ffffff;
    border-radius: 0.25rem;
    color: rgb(110, 9, 177);
    box-shadow: 0 1px 2px rgba(100, 100, 100, 0.4);
}

/*=====  End of BUTTON  ======*/

/*================================
 =            FEATURES            =
 ================================*/
.feature-item {
    transition: all 0.2s ease-in-out 0s !important;
}
.feature-item:hover {
    transform: translateY(-13px);
}

.progress-bar {
    background-color: #ff00cc;
}

/*=====  End of FEATURES  ======*/

/*=============================
 =            TESTI            =
 =============================*/
.testi-img img {
    height: 60px;
    width: 60px !important;
    border-radius: 50%;
    display: inline-block !important;
}

.testi-details {
    text-align: left;
}

.testi-text {
    font-weight: 300;
    padding-top: 50px;
    padding-bottom: 50px;
}

.testi-icon {
    color: #ff00cc;
}

.testi-content {
    margin-top: 80px;
    padding-bottom: 80px;
}

.testi-item {
    margin-left: 15px;
    margin-right: 15px;
}

.owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    cursor: pointer;
}
.owl-dot span {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    border-radius: 50%;
    background-color: rgba(255, 0, 204, 0.3);
    text-indent: -999em;
    cursor: pointer;
    position: absolute;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
    -webkit-transition:
        box-shadow 0.3s ease,
        background-color 0.3s ease;
    transition:
        box-shadow 0.3s ease,
        background-color 0.3s ease;
}
.owl-dot.active > span {
    background-color: transparent;
    box-shadow: 0 0 0 2px #ff00cc;
}

.owl-dots {
    position: absolute;
    bottom: 0;
    display: block;
    text-align: center;
    left: 0;
    right: 0;
    clear: both;
    padding: 0;
    list-style: none;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.heading-section {
    position: relative;
}

/*=====  End of TESTI  ======*/

/*=================================
 =            PORTFOLIO            =
 =================================*/
.filter-button-group {
    margin-bottom: 15px;
}
.filter-button-group a {
    margin: 5px;
    cursor: pointer;
}

.grid-link a {
    margin: 5px;
    font-size: 20px;
    color: #000;
}

.grid-portfolio {
    overflow: hidden;
}

.gutter-sizer {
    width: 2%;
}

.grid-sizer,
.grid-item {
    width: 32%;
}

.grid-item {
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
}
.grid-item:hover .grid-info {
    top: 50%;
    opacity: 1;
}

.grid-item-wrapper:before {
    content: '';
    display: block;
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease;
    opacity: 0;
}

.grid-item-wrapper:hover:before {
    opacity: 1;
}

.grid-info {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease;
}

.lightcase-icon-close:before {
    content: '\e870';
    font-family: 'Linearicons-Free';
}

.grid-title {
    text-align: center;
}

/*=====  End of PORTFOLIO  ======*/

/*==============================
 =            SKILLS            =
 ==============================*/
.skill-item {
    width: 100%;
    position: relative;
    padding: 10px 0;
}
.skill-item p {
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 500;
}
.skill-item .text-muted {
    color: #939393;
}

.progress-bar,
.progress {
    transition: all 4s;
}

/*=====  End of SKILLS  ======*/

/*==============================
 =            CLIENT            =
 ==============================*/
.client-slider .owl-nav {
    display: none;
}

.client-slider .client-item {
    padding: 0 40px;
}

/*=====  End of CLIENT  ======*/

.overlay {
    color: #fff;
}
.overlay:before {
    position: absolute;
    display: block;
    content: '';
    opacity: 0.9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #7f3f98;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #7f459b, #3190d5);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #7f459b, #3190d5);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.overlay.bg-white {
    color: #000;
}
.overlay.bg-white:before {
    background: #fff;
    opacity: 0.7;
}
