.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    background: transparent;
}

.trait-bars-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 80%;
    max-width: 600px;
    margin: 20px auto;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.trait-bar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.trait-bar-label {
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
}

.trait-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.trait-bar-background {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;
    width: 100%;
    background: #e0e0e0;
    border-radius: 5px;
    z-index: 1;
}

.trait-bar-inner {
    height: 30px;
    width: 0;
    transition: width 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    color: #fff;
    border-radius: 10px;
    z-index: 2;
}

@keyframes growBar {
    from {
        height: 0;
    }
    to {
        height: 100%;
    }
}
