.jumbotron {
    color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    border-radius: 0;
    margin-bottom: 0;
    overflow: hidden;
    min-height: 100vh;
}

.jumbotron-colum-view {
    display: flex;
    flex-direction: column;
}

@media (max-width: 767px) and (orientation: landscape) {
    .jumbotron {
        min-height: 500px;
    }
}
.jumbotron .container {
    position: relative;
    z-index: 1;
}
.jumbotron:before {
    position: absolute;
    display: block;
    content: '';
    opacity: 0.8;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #6f00ff;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #7c4a9dff, #6c70b4ff);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #7c4a9dff, #6c70b4ff);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.jumbotron h1 {
    line-height: 0.9;
    font-weight: bold;
    display: inline-block;
    padding: 30px;
    font-size: 9rem;
}

.jumbotron h1, .jumbotron-element-fade-in {
    opacity: 0;
    animation: 1s fadeInFromTop cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s forwards;
    animation-delay: 0.8s;
}

.jumbotron-single h1 {
    font-size: 3rem !important;
}

@media (min-width: 767px) {
    .jumbotron h1 {
        border: 15px solid #fff;
    }
}

@media (max-width: 767px) {
    .jumbotron h1 {
        font-size: 4rem;
    }
    .grid-item {
        width: 100%;
    }
    .progress {
        height: 30px;
        font-size: 16px;
    }
}

/*==========================================
 =            FORM RECTANGLE ETC            =
 ==========================================*/
.rectangle-1 {
    width: 2000px;
    height: 400px;
    background: #7c4a9dff    ;
    background: -webkit-linear-gradient(to right, #7c4a9dff, #6c70b4ff);
    background: linear-gradient(to right, #7c4a9dff, #6c70b4ff);
    transform-origin: 50% 150% 0;
    position: absolute;
    left: 0;
    z-index: 0;
    transform: scale(1) rotate(-10deg);
}

.rectangle-2 {
    width: 2000px;
    height: 400px;
    background: #7c4a9dff;
    background: -webkit-linear-gradient(to left, #7c4a9dff, #6c70b4ff);
    background: linear-gradient(to left, #7c4a9dff, #6c70b4ff);
    transform-origin: 70% 100% 0;
    position: absolute;
    right: 0;
    z-index: 0;
    transform: scale(3) rotate(50deg);
}

@keyframes fade-in-right {
    from {
        opacity: 0;
        transform: translateX(-100vw) rotate(-15deg);
    }
    to {
        opacity: 1;
        transform: translateX(0) rotate(-15deg);
    }
}

@keyframes grow1 {
    from {
        opacity: 0;
        transform: scale(2) rotate(-10deg);
    }
    to {
        opacity: 1;
        transform: scale(1) rotate(-10deg);
    }
}

@keyframes grow2 {
    from {
        opacity: 0;
        transform: scale(2) rotate(50deg);
    }
    to {
        opacity: 1;
        transform: scale(1) rotate(50deg);
    }
}

@keyframes fadeInFromTop {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.rectangle-1 {
    opacity: 0;
    animation: grow1 ease 1s forwards;
    box-shadow: 0px 20px 30px 0px rgba(9, 21, 54, 0.25) !important;
}

.rectangle-2 {
    opacity: 0;
    animation: grow2 ease 1s forwards;
    box-shadow: 0px 20px 30px 0px rgba(9, 21, 54, 0.25) !important;
}

.rectangle-transparent-1 {
    width: 500px;
    height: 500px;
    border: 15px solid rgba(255, 255, 255, 0.08);
    position: absolute;
    left: -5%;
    bottom: -10%;
    display: block;
    animation: floating-slow ease-in-out 12s infinite;
}

.rectangle-transparent-22 {
    width: 500px;
    height: 500px;
    border: 15px solid rgba(255, 255, 255, 0.08);
    position: absolute;
    left: -5%;
    bottom: -10%;
    display: block;
    animation: floating-slow ease-in-out 12s infinite;
}

.rectangle-transparent-2 {
    width: 600px;
    height: 600px;
    border: 30px solid rgba(255, 255, 255, 0.08);
    position: absolute;
    right: -10%;
    top: 5%;
    display: block;
    animation: floating-slow ease-in-out 12s infinite;
}

.circle-1 {
    width: 50px;
    height: 50px;
    border: 2px solid rgba(255, 255, 255, 0.08);
    position: absolute;
    display: block;
    border-radius: 50%;
    transform-origin: 50% 50%;
    left: 5%;
    top: 50%;
    animation:
        fadeInFromTop 0.5s linear forwards,
        floating ease 4s infinite;
}

.circle-big {
    width: 2000px;
    height: 2000px;
    border: 100px solid rgba(255, 255, 255, 0.08);
    position: absolute;
    display: block;
    border-radius: 50%;
    transform-origin: 50% 50%;
    left: 5%;
    top: 50%;
    animation:
        fadeInFromTop 0.5s linear forwards,
        floating ease 4s infinite;
}

.circle-2 {
    width: 30px;
    height: 30px;
    top: 20%;
    left: 83%;
    border: 2px solid rgba(255, 255, 255, 0.08);
    position: absolute;
    display: block;
    border-radius: 50%;
    transform-origin: 50% 50%;
    animation:
        fadeInFromTop 0.5s linear forwards,
        floating ease-in-out 4s infinite;
}

.circle-3 {
    top: 80%;
    right: 25%;
    width: 40px;
    height: 40px;
    border: 2px solid rgba(255, 255, 255, 0.08);
    position: absolute;
    display: block;
    border-radius: 50%;
    animation:
        fadeInFromTop 0.5s linear forwards,
        floating ease-in-out 4s infinite;
}

@keyframes floating {
    0% {
        transform: translate(0%, 0%) rotate(25deg);
    }
    25% {
        transform: translate(5%, 15%) rotate(25deg);
    }
    50% {
        transform: translate(10%, 5%) rotate(25deg);
    }
    75% {
        transform: translate(0%, 15%) rotate(25deg);
    }
    100% {
        transform: translate(0%, 0%) rotate(25deg);
    }
}

@keyframes floating-slow {
    0% {
        transform: translate(0%, 0%) rotate(25deg);
    }
    25% {
        transform: translate(1%, 3%) rotate(25deg);
    }
    50% {
        transform: translate(2%, 1%) rotate(25deg);
    }
    75% {
        transform: translate(0%, 3%) rotate(25deg);
    }
    100% {
        transform: translate(0%, 0%) rotate(25deg);
    }
}

.triangle {
    position: absolute;
}

.triangle-1 {
    right: 0;
    animation:
        fadeInFromTop 0.5s linear forwards,
        floating ease-in-out 6s infinite;
}
.triangle-1 img {
    height: 50px;
    width: 50px;
    transform: rotate(30deg);
}

.triangle-2 {
    top: 9%;
    left: 20%;
    animation:
        fadeInFromTop 0.5s linear forwards,
        floating ease-in-out 8s infinite;
}
.triangle-2 img {
    width: 75px;
    height: 75px;
    transform: rotate(18deg);
}

.triangle-3 {
    top: 80%;
    left: 15%;
    animation:
        fadeInFromTop 0.5s linear forwards,
        floating ease-in-out 10s infinite;
}
.triangle-3 img {
    width: 45px;
    height: 45px;
    transform: rotate(40deg);
}

.triangle-4 {
    top: 60%;
    right: 15%;
    animation:
        fadeInFromTop 0.5s linear forwards,
        floating ease-in-out 5s infinite;
}
.triangle-4 img {
    width: 45px;
    height: 45px;
    transform: rotate(-40deg);
}
/*=====  End of FORM RECTANGLE ETC  ======*/