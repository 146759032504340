@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.event-list {
    margin-top: 20px;
    font-family: 'Lato', sans-serif;
}

.event-item {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    max-width: 300px;
    margin: 10px auto;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.carousel .slide {
    background: none;
    transform: scale(0.8);
    opacity: 0.5;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.carousel .slide.selected {
    transform: scale(1);
    opacity: 1;
}

.event-item h3 {
    margin-top: 0;
    color: #0c1d6dff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif; 
    font-weight: 400;
}

.event-item p {
    margin: 5px 0;
    color: #0c1d6dff;
    font-size: 0.9rem;
    font-family: 'Lato', sans-serif; 
    font-weight: 400;
}

.event-location {
    color: #0c1d6dff;
    font-size: 0.8rem;
    font-family: 'Lato', sans-serif; 
    font-weight: 300;
}

.event-item img {
    margin-top: 10px;
    max-width: 100%;
    border-radius: 8px;
}

.event-item a {
    color: #007bff;
    text-decoration: none;
    font-size: 0.9rem;
    font-family: 'Lato', sans-serif; 
    font-weight: 400;
}

.event-item a:hover {
    text-decoration: underline;
}

.carousel .control-dots .dot {
    background: #007bff;
}

.carousel .control-dots .dot.selected {
    background: #0c1d6dff;
}

.carousel .control-arrow {
    color: #007bff;
}

.carousel .control-arrow:hover {
    background: rgba(0, 123, 255, 0.1);
}
