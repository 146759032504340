@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

#header-navbar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 11;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    font-family: 'Lato', Arial, Helvetica, sans-serif; /* Apply Lato font */
}

.navbar-brand {
    font-size: 40px;
    color: #000;
    font-family: 'Lato', Arial, Helvetica, sans-serif; /* Apply Lato font */
}

.custom-navbar-nav .custom-nav-link {
    color: #fff;
    transition: 0.3s ease;
    font-family: 'Lato', Arial, Helvetica, sans-serif; /* Apply Lato font */
}

.custom-navbar-nav:hover li a {
    opacity: 0.5;
}

.custom-navbar-nav:hover li a:hover {
    opacity: 1;
}

.navbar-toggler {
    font-size: 1.5rem;
    color: #fff;
    font-family: 'Lato', Arial, Helvetica, sans-serif; /* Apply Lato font */
}

.navbar .show .custom-nav-item {
    text-align: right;
}

/*==================================
 =            RESPONSIVE            =
 ==================================*/
@media (max-width: 992px) {
    .not-on-top #header-navbar {
        position: fixed;
        top: 0;
        height: auto;
        width: 100%;
        background: #ff00cc;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #333399, #ff00cc);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #333399, #ff00cc);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        z-index: 11;
    }
    #header-navbar {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
    .navbar-transparent .navbar-collapse {
        background: #ff00cc;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #333399, #ff00cc);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #333399, #ff00cc);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        padding: 10px;
        font-family: 'Lato', Arial, Helvetica, sans-serif; /* Apply Lato font */
    }
}
