@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

.non-jumbotron-banner {
    content: '';
    opacity: 0.8;
    display: block;
    width: 100%;
    height: 100px;
    z-index: -1;
    margin-bottom: 40px;
    background: #ff00cc;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #7f459b, #3190d5);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #7f459b, #3190d5);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-family: 'Lato', Arial, Helvetica, sans-serif; /* Apply Lato font */
}
