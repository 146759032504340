#delete-account-form {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px; /* Adjust the width as needed */
    margin: auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for depth */
    background-color: #ffffff; /* Adjust the background color as needed */
    border-radius: 8px;
}

#delete-account-form input {
    height: 56px;
}

#delete-account-form input[type='text'],
#delete-account-form input[type='email'],
#delete-account-form button {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

#delete-account-form button {
    padding: 10px; /* Match the input padding */
    margin-top: 10px;
    background-color: #4e73df; /* Example button color */
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

/* Adjust the button hover effect */
#delete-account-form button:hover {
    background-color: #6c8ae4;
}
