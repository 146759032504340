footer {
    background-color: #ffffff;
    padding-bottom: 35px;
}

.footer_column {
    width: 50%;
    text-align: center;
    padding-top: 30px;
    float: left;
}

footer .footer_column h3 {
    color: #b3b3b3;
    text-align: center;
}

footer .footer_column p {
    color: #717070;
    background-color: #ffffff;
}

footer .footer_column p {
    padding-left: 30px;
    padding-right: 30px;
    text-align: justify;
    line-height: 25px;
    font-weight: lighter;
    margin-left: 20px;
    margin-right: 20px;
}

.footer_banner {
    background-color: #b3b3b3;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 0px;
    /* background-image: url(../images/pattern.png);  TODO - Unknown */
    background-repeat: repeat;
}

footer {
    display: inline-block;
}

@media (min-width: 321px) and (max-width: 767px) {
    .footer_column {
        width: 100%;
        margin-top: 0px;
    }
}
