.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 20px 0;
}

.carousel-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;
  z-index: 1; /* Ensure buttons are above the carousel items */
}

.carousel-button:focus {
  outline: none;
}

.carousel-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 200px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.carousel-item {
  width: 300px;
  height: 100%;
  padding: 0px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  opacity: 0.5;
  z-index: 0;
}

.carousel-item.active {
  transform: translateX(-50%) scale(1);
  opacity: 1;
  z-index: 1;
}

.carousel-item.inactive-left {
  transform: translateX(-150%) scale(0.8);
  opacity: 0.5;
  z-index: 0;
  background-color: lightblue;
}

.carousel-item.inactive-right {
  transform: translateX(50%) scale(0.8);
  opacity: 0.5;
  z-index: 0;
  background-color: lightcoral;
}

.trait-insight {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  background-color: inherit;
}
