@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

#login-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5% auto;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

#login-box h1 {
    margin: 0 0 0px 0;
    font-weight: 700; 
    font-size: 24px;
    color: #6c70b4ff;
    font-family: 'Lato', sans-serif;
}

.left,
.right {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding: 40px;
}

.left {
    box-sizing: border-box;
    height: 100%;
    position: relative;
}

.logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

.form-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    background: radial-gradient(circle, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 100%);
    padding: 0px;
}

.right .loginwith {
    display: block;
    margin-bottom: 40px;
    font-size: 28px;
    color: #6c70b4ff;
    text-align: center;
    font-family: 'Lato', sans-serif; 
}

button.submit {
    margin-top: 0.5rem;
}

.or {
    display: flex;
    justify-content: center;
    align-items: center;
}

.or p {
    width: 40px;
    height: 40px;
    background: #6c70b4ff;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-family: 'Roboto', sans-serif; 
    font-weight: 500;
}

@media (min-width: 767px) {
    #login-box {
        width: 640px;
        min-height: 550px;
    }
}

@media (max-width: 767px) {
    #login-box {
        flex-direction: column;
        width: 300px;
    }
}

.input-error-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: red;
    margin-bottom: 0;
    padding: 4px;
}

select,
input[type='text'],
input[type='password'] {
    display: block;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 4px;
    border: none;
    border-bottom: 1px solid #aaa;
    font-family: 'Lato', sans-serif; 
    font-weight: 400;
    font-size: 15px;
    transition: 0.2s ease;
}

select {
    font-family: 'Lato', sans-serif; 
    font-weight: 300;
}

input[type='text'],
input[type='password'] {
    width: 220px;
    height: 32px;
}

input[type='text']:focus,
input[type='password']:focus {
    border-bottom: 2px solid #16a085;
    color: #16a085;
    transition: 0.2s ease;
}

input[type='submit'] {
    margin-top: 28px;
    width: 120px;
    height: 32px;
    background: #16a085;
    border: none;
    border-radius: 2px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    transition: 0.1s ease;
    cursor: pointer;
}

input[type='submit']:hover,
input[type='submit']:focus {
    opacity: 0.8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    transition: 0.1s ease;
}

input[type='submit']:active {
    opacity: 1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    transition: 0.1s ease;
}

button.social-signin {
    margin-bottom: 20px;
    width: 220px;
    height: 36px;
    border: none;
    border-radius: 2px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    transition: 0.2s ease;
    cursor: pointer;
}

button.social-signin:hover,
button.social-signin:focus {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    transition: 0.2s ease;
}

button.social-signin:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    transition: 0.2s ease;
}

button.social-signin.facebook {
    background: #32508e;
}

button.social-signin.google {
    background: #dd4b39;
}

button.social-signin.apple {
    background: #000;
}

.newsletter-label {
    color: #7c4a9dff;
    font-family: 'Lato', sans-serif; 
    font-weight: 300;
}
