@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Bebas+Neue&display=swap');

.about-page .container {
    font-family: 'Lato', sans-serif;
    padding: 20px;
    background-color: rgb(243, 243, 243);
    border-radius: 8px;

}

.about-page .row {
    background-color: #ffffff00;
    border-radius: 8px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);
    padding: 20px;
}

.about-page h1, .about-page h2 {
    font-family: 'Lato', sans-serif; 
    font-weight: 700;
    color: #6c70b4ff;
    margin-bottom: 20px;
}

.about-page h1 {
    font-size: 2rem;
}

.about-page h2 {
    font-size: 1.75rem;
    margin-bottom: 15px;
}

.about-page h3 {
    font-family: 'Lato', sans-serif; 
    font-weight: 700;
    color: #fe7d02ff;
    font-size: 1.5rem;
    margin-top: 20px;
}

.about-page p {
    font-family: 'Lato', sans-serif; 
    font-weight: 400;
    font-size: 1rem;
    color: #0c1d6dff;
    line-height: 1.6;
    margin-bottom: 15px;
}

.about-page p.fw-bold {
    font-weight: 700;
}

.about-page ul {
    font-family: 'Lato', sans-serif; 
    font-weight: 400;
    font-size: 1rem;
    color: #0c1d6dff;
    margin-bottom: 15px;
}

.about-page ul li {
    margin-bottom: 10px;
}

.about-page .section {
    padding: 20px;
    background-color: #ffffff00;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}
